// extracted by mini-css-extract-plugin
export var gridContainer = "styles-module--grid-container--71f25";
export var gridContent = "styles-module--grid-content--ea5aa";
export var paddingGlobal = "styles-module--padding-global--21d15";
export var paddingHeader = "styles-module--padding-header--4e4ee";
export var paragraphFontSize = "styles-module--paragraph-font-size--d66ef";
export var primaryButton = "styles-module--primary-button--3b926";
export var sanityImage = "styles-module--sanityImage--b2e37";
export var textBase = "styles-module--text-base--d5734";
export var textDescription = "styles-module--text-description--f4dc5";
export var textHeadline = "styles-module--text-headline--725bf";
export var titleFontSize = "styles-module--title-font-size--75835";