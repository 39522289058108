export const IconYoutube = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.4em"
    height="1em"
    viewBox="0 0 43 30"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M15.74.361c-.035.053-.868.088-2.21.088-4.368 0-8.754.482-9.307 1.026a.422.422 0 0 1-.245.114c-.07 0-.123.035-.123.088 0 .044-.061.088-.14.088-.299 0-1.79 1.684-1.79 2.035 0 .087-.035.157-.087.157-.044 0-.088.062-.088.14 0 .08-.062.246-.123.37-.175.324-.544 2.253-.71 3.788-.123 1.088-.14 2.167-.106 7.456.018 4.228.07 6.395.14 6.842.176 1.132.544 2.93.623 3.114.562 1.263.623 1.368 1.299 2.053.377.386.754.71.824.71.079 0 .158.061.193.132.026.07.132.131.228.131.097 0 .176.035.176.08 0 .104 1.157.429 1.885.526 2.825.394 7.22.552 15.658.552 8.464-.009 12.06-.123 15.245-.509 1.017-.114 2.219-.42 2.28-.579.018-.035.105-.07.193-.07.097 0 .202-.052.237-.114.035-.052.184-.166.316-.237.491-.245 1.29-1.184 1.649-1.93.395-.798.395-.798.64-2.06.43-2.211.43-2.176.465-8.71.035-5.334.018-6.448-.105-7.5-.14-1.22-.509-3.272-.65-3.614-.28-.676-.648-1.36-.841-1.58a57.59 57.59 0 0 1-.43-.49c-.228-.272-1.035-.869-1.175-.869-.053 0-.15-.053-.22-.123C38.906.931 34.494.45 30.135.45 28.8.449 27.968.414 27.933.36 27.898.3 25.766.273 21.837.273c-3.93 0-6.062.027-6.096.088Zm4.421 9.447c.316.158.606.325.658.377a.408.408 0 0 0 .237.088c.088 0 .167.026.184.061.027.07 1.08.623 3.316 1.755.649.324 1.202.622 1.228.649.026.026.298.175.614.324.316.15.886.456 1.272.667.386.22.763.421.833.456.509.202 1.044.526.93.544-.08.017-.14.07-.14.114s-.062.079-.132.079-.395.14-.72.324c-.333.176-.833.439-1.122.588-.29.15-.535.298-.553.333-.017.044-.087.07-.149.07-.07 0-.316.106-.544.229-.456.254-2.192 1.157-3.535 1.842-.456.236-.842.456-.86.49-.017.045-.096.071-.183.071a.425.425 0 0 0-.237.079c-.044.044-.57.333-1.176.65-.605.306-1.605.832-2.236 1.157-.623.325-1.193.632-1.246.675-.088.07-.114-1.28-.114-6.719V7.896l1.561.807c.851.447 1.807.938 2.114 1.105Z"
    />
  </svg>
);
