export const IconLinkedin = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
  >
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        d="M2.547.578C1.637.888.899 1.654.635 2.558c-.08.28-.091 1.522-.091 12.544 0 12.018 0 12.235.114 12.59.28.91 1.082 1.694 1.997 1.963.28.08 1.523.092 12.545.092 12.017 0 12.235 0 12.59-.115.91-.28 1.693-1.082 1.962-1.997.08-.28.092-1.522.092-12.539 0-11.016-.012-12.257-.092-12.538C29.483 1.643 28.7.841 27.79.561c-.354-.115-.566-.115-12.618-.115L2.919.452l-.372.126Zm5.27 3.954c1.294.395 2.078 1.603 1.877 2.896-.286 1.837-2.375 2.793-3.943 1.803-1.138-.721-1.528-2.203-.904-3.428.155-.303.641-.813.95-.99.572-.338 1.437-.458 2.02-.28Zm13.718 7.531c.595.15 1.499.613 1.997 1.03a5.144 5.144 0 0 1 1.843 3.4c.04.332.062 2.146.062 4.698v4.15h-4.349v-3.915c0-2.404-.023-4.029-.057-4.218a2.269 2.269 0 0 0-1.259-1.62c-.332-.16-.43-.177-.916-.177-.486 0-.583.017-.915.178a2.269 2.269 0 0 0-1.26 1.62c-.034.188-.056 1.813-.056 4.217v3.914h-4.35V12.178H16.62l.017.893.017.887.338-.39c.72-.835 1.802-1.459 2.832-1.619.435-.069 1.18-.017 1.712.114ZM9.299 18.76v6.581H4.95V12.178H9.3v6.581Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M.544.447h29.3v29.3H.544z" />
      </clipPath>
    </defs>
  </svg>
);
