// extracted by mini-css-extract-plugin
export var certificate = "styles-module--certificate--a5622";
export var column = "styles-module--column--e1585";
export var container = "styles-module--container--8ea9e";
export var footer = "styles-module--footer--a2167";
export var formSended = "styles-module--formSended--c99bc";
export var gridContainer = "styles-module--grid-container--5bc31";
export var gridContent = "styles-module--grid-content--23352";
export var icons = "styles-module--icons--c866e";
export var paddingGlobal = "styles-module--padding-global--e569d";
export var paddingHeader = "styles-module--padding-header--79c7f";
export var paragraphFontSize = "styles-module--paragraph-font-size--4fe24";
export var primaryButton = "styles-module--primary-button--0a7ba";
export var section = "styles-module--section--21e2a";
export var social = "styles-module--social--4cd15";
export var text = "styles-module--text--e665b";
export var textBase = "styles-module--text-base--06f49";
export var textDescription = "styles-module--text-description--fdf4a";
export var textHeadline = "styles-module--text-headline--35dd6";
export var textSection = "styles-module--textSection--8b95a";
export var titleFontSize = "styles-module--title-font-size--8ee14";