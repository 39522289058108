// extracted by mini-css-extract-plugin
export var btnAndMsg = "styles-module--btnAndMsg--5ced9";
export var btnSubmit = "styles-module--btnSubmit--d424f";
export var errorMessage = "styles-module--errorMessage--f1b89";
export var gridContainer = "styles-module--grid-container--e84de";
export var gridContent = "styles-module--grid-content--9d2a5";
export var inputError = "styles-module--inputError--858eb";
export var newsLetterForm = "styles-module--newsLetterForm--aa448";
export var paddingGlobal = "styles-module--padding-global--035df";
export var paddingHeader = "styles-module--padding-header--3f232";
export var paragraphFontSize = "styles-module--paragraph-font-size--3e972";
export var primaryButton = "styles-module--primary-button--9bb9f";
export var row = "styles-module--row--edd41";
export var spin = "styles-module--spin--a6637";
export var spinner = "styles-module--spinner--25ff5";
export var textBase = "styles-module--text-base--a77f6";
export var textDescription = "styles-module--text-description--f3ddb";
export var textHeadline = "styles-module--text-headline--aeb5a";
export var title = "styles-module--title--e99e8";
export var titleFontSize = "styles-module--title-font-size--871b5";