import { IReference } from '../types/commons';

function getParent(data: IReference): string[] {
  if (data.parentPage) {
    return [
      ...getParent(data.parentPage),
      data.slug.current,
    ]
  }
  return [data.slug.current];
}

export function formatInternalLink(reference: IReference) {

  if (reference?.parentPage && reference?.parentPage.slug) {
    return `/${[...getParent(reference.parentPage), reference.slug?.current].join('/')}`;
  }
  return reference?.slug?.current ? `/${reference.slug.current}` : '/';
}
