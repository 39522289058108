import { ISocialLink } from "../../../types/commons";
import {
  FaFacebookSquare,
  FaPinterestSquare,
} from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { IconInstagram } from "../../Icons/IconInstagram";
import { IconTiktok } from "../../Icons/IconTiktok";
import { IconLinkedin } from "../../Icons/IconLinkedin";
import { IconYoutube } from "../../Icons/IconYoutube";

interface ISocialLinkProps {
  data: ISocialLink;
}

export default function SocialLink({ data }: ISocialLinkProps) {
  return (
    <a
      href={data.externalLink}
      target="_blank"
      title={`Go to ${data.name || "link"}`}
      tabIndex={0}
      className="outlineGlobalV2"
    >
      {SocialIcons[data.type]}
    </a>
  );
}

const SocialIcons = {
  instagram: (
    <p>
      <IconInstagram />
    </p>
  ),
  tiktok: (
    <p>
      <IconTiktok />
    </p>
  ),
  linkedin: (
    <p>
      <IconLinkedin />
    </p>
  ),
  youtube: (
    <p>
      <IconYoutube />
    </p>
  ),
  facebook: (
    <p>
      <FaFacebookSquare />
    </p>
  ),
  twitter: (
    <p>
      <FaSquareXTwitter />
    </p>
  ),
  pinterest: (
    <p>
      <FaPinterestSquare />
    </p>
  ),
};
