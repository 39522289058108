import { graphql, useStaticQuery } from 'gatsby';

interface ISiteMetaData {
  title: string
  description: string
  siteUrl: string
  projectId: string
  dataset: string
  sanityToken: string
}

export function useSiteMetaData() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
          dataset
          projectId
          sanityToken
        }
      }
    }
  `);

  return data?.site?.siteMetadata as ISiteMetaData;
}
