import classNames from "classnames";
import { Link } from "gatsby";
import { useEffect, useState } from "react";
import {
  IInternalLink,
  INavLink,
  ISanityHeader,
  ISanitySettings,
} from "../../types/commons";
import { formatInternalLink } from "../../utils/linksUtils";
import InternalLink from "../InternalLink";
import SanityImage from "../SanityImage";
import * as styles from "./styles.module.scss";

interface IHeaderProps {
  data: {
    sanitySettings?: ISanitySettings | null;
    sanityHeader?: ISanityHeader | null;
  };
  uri: string;
}

function splitNavItems(navItems: INavLink[]) {
  const mid = Math.ceil(navItems.length / 2);
  const firstHalf = navItems.slice(0, mid);
  const secondHalf = navItems.slice(mid);
  return [firstHalf, secondHalf];
}

function checkIfNeedLightTheme(pages: IInternalLink[], uri: string): boolean {
  const pagesURIs = pages.map((page) => formatInternalLink(page.reference));
  return pagesURIs.includes(uri);
}

export default function Header({ data, uri }: IHeaderProps) {
  if (!data) {
    return null;
  }

  const { sanitySettings, sanityHeader } = data;

  if (!sanityHeader || !sanitySettings) {
    return null;
  }

  const hasDisclaimer = sanitySettings?._rawDisclaimer.isActive;
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [top, setTop] = useState(hasDisclaimer ? "2.375rem" : "0px");
  const [hasScroll, setHasScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!hasDisclaimer) return;

      const scrollTop = window.scrollY;
      const maxScroll = 38;
      const minTop = 0;
      const maxTop = 38;

      if (scrollTop > maxScroll) {
        setTop("0px");
      } else {
        const newTop = Math.max(minTop, maxTop - scrollTop);
        setTop(`${newTop}px`);
      }

      if (
        (!hasDisclaimer && scrollTop > 0) ||
        (hasDisclaimer && scrollTop > maxScroll)
      ) {
        setHasScroll(true);
      } else {
        setHasScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isLightTheme = checkIfNeedLightTheme(sanityHeader._rawLightTheme, uri);
  // const isHome = uri === "/";

  const navItems = !!sanityHeader._rawNavItems ? sanityHeader._rawNavItems : [];
  const [leftLinks, rightLinks] = splitNavItems(navItems);

  function NavItem({ item }: { item: INavLink }) {
    const isActive =
      item.pageReference?.reference?.slug?.current === uri.split("/")[1];
    return (
      <li role="menuitem">
        <InternalLink
          reference={item.pageReference.reference}
          className={`${classNames(styles.link, isActive && styles.active)} outlineGlobal`}
          onClick={() => setIsMobileMenuOpen(false)}
          tabIndex={0}
        >
          {item.label}
        </InternalLink>
      </li>
    );
  }

  return (
    <header
      className={classNames(
        styles.header,
        hasScroll && styles.scrolled,
        isLightTheme && styles.light,
      )}
      style={{ top }}
      role="banner"
    >
      <a href="#main-content" className={styles.skipLink}>
        Skip to content
      </a>
      <a href="#footer" className={styles.skipLink}>
        Skip to footer
      </a>

      <div className={styles.container}>
        <button
          type="button"
          className={`${classNames(
            styles.hamburger,
            isMobileMenuOpen && styles.active,
          )} outlineGlobal`}
          onClick={() => setIsMobileMenuOpen((prev) => !prev)}
          aria-expanded={isMobileMenuOpen}
          aria-controls="primary-navigation"
          aria-label="hamburger menu"
        >
          <span />
        </button>

        <nav
          className={classNames(
            styles.navigation,
            isMobileMenuOpen && styles.open,
          )}
          role="navigation"
          aria-label="Main navigation"
          id="primary-navigation"
        >
          {leftLinks.length > 0 && (
            <ul className={styles.links} role="menu">
              {leftLinks.map((item) => (
                <NavItem key={item._key} item={item} />
              ))}
            </ul>
          )}

          <Link
            to="/"
            className={`${classNames(styles.logo)} outlineGlobal`}
            onClick={() => setIsMobileMenuOpen(false)}
          >
            <SanityImage
              src={sanitySettings.logo.asset.url}
              alt={sanitySettings.logo.alt}
            />
          </Link>

          {rightLinks.length > 0 && (
            <ul className={styles.links} role="menu">
              {rightLinks.map((item) => (
                <NavItem key={item._key} item={item} />
              ))}
            </ul>
          )}
        </nav>
      </div>
    </header>
  );
}
