exports.components = {
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-templates-home-page-template-index-tsx": () => import("./../../../src/templates/HomePageTemplate/index.tsx" /* webpackChunkName: "component---src-templates-home-page-template-index-tsx" */),
  "component---src-templates-main-page-template-index-tsx": () => import("./../../../src/templates/MainPageTemplate/index.tsx" /* webpackChunkName: "component---src-templates-main-page-template-index-tsx" */),
  "component---src-templates-not-found-page-template-index-tsx": () => import("./../../../src/templates/NotFoundPageTemplate/index.tsx" /* webpackChunkName: "component---src-templates-not-found-page-template-index-tsx" */),
  "component---src-templates-server-error-page-template-index-tsx": () => import("./../../../src/templates/ServerErrorPageTemplate/index.tsx" /* webpackChunkName: "component---src-templates-server-error-page-template-index-tsx" */),
  "component---src-templates-work-detail-page-template-index-tsx": () => import("./../../../src/templates/WorkDetailPageTemplate/index.tsx" /* webpackChunkName: "component---src-templates-work-detail-page-template-index-tsx" */),
  "component---src-templates-work-page-template-index-tsx": () => import("./../../../src/templates/WorkPageTemplate/index.tsx" /* webpackChunkName: "component---src-templates-work-page-template-index-tsx" */)
}

