export const IconTiktok = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      fill="#fff"
      d="M2.915.57c-.96.288-1.746.934-2.164 1.775-.418.852-.39-.114-.374 12.957l.016 11.704.147.396c.39 1.08 1.177 1.856 2.245 2.214l.396.13h23.716l.396-.13c1.069-.358 1.877-1.167 2.268-2.257l.125-.353.016-11.704c.016-13.082.043-12.116-.38-12.957a3.493 3.493 0 0 0-2.246-1.79C26.664.446 26.431.446 14.99.45H3.322l-.407.12Zm15.732 5.123c.227 1.226.905 2.376 1.866 3.142.726.58 1.594.965 2.49 1.112l.417.065v3.255l-.662-.032c-.732-.033-1.269-.152-1.882-.412-.776-.331-1.546-.934-2.083-1.623l-.228-.298-.027 4.498-.027 4.498-.152.543a6.125 6.125 0 0 1-1.437 2.588c-1.883 2.002-4.926 2.447-7.291 1.069-.483-.282-1.096-.776-1.416-1.14-2.164-2.441-2.04-6.023.277-8.253 1.096-1.052 2.327-1.59 3.797-1.655l.662-.032V15.91h-.342c-.423 0-1.096.168-1.448.358-.602.326-1.167.982-1.41 1.633-.603 1.617.276 3.43 1.914 3.95.597.19 1.324.19 1.926 0 .933-.293 1.698-1.009 2.029-1.899.119-.325.124-.352.157-4.666.022-2.387.038-5.605.043-7.151l.006-2.806h2.755l.066.364Z"
    />
  </svg>
);
