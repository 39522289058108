import imageUrlBuilder from '@sanity/image-url';
import { useSiteMetaData } from './useSiteMetaData';

export function useImageBuilder() {
  const { projectId, dataset } = useSiteMetaData();

  const sanityClient = {
    projectId,
    dataset,
  };

  const builder = imageUrlBuilder(sanityClient);
  return builder;
}
