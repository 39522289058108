import { Helmet } from "react-helmet";

interface IAnalyticsProps {
  gaId: string;
  metaPixelId: string;
  linkedInPartnerId: string;
}

const Analytics = ({
  gaId,
  linkedInPartnerId,
  metaPixelId,
}: IAnalyticsProps) => {
  return (
    <>
      <Helmet>
        {!!gaId && (
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`}
          />
        )}

        {!!gaId && (
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${gaId}');
          `}
          </script>
        )}

        {!!metaPixelId && (
          <script type="text/javascript">
            {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${metaPixelId}');
            fbq('track', 'PageView');
          `}
          </script>
        )}

        {!!metaPixelId && (
          <noscript
            dangerouslySetInnerHTML={{
              __html: `
              <img
                height="1"
                width="1"
                style="display: none;"
                src="https://www.facebook.com/tr?id=${metaPixelId}&ev=PageView&noscript=1"
                alt="Facebook Pixel"
              />
            `,
            }}
          />
        )}

        {!!linkedInPartnerId && (
          <script type="text/javascript">
            {`
                _linkedin_partner_id = "${linkedInPartnerId}";
                window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                window._linkedin_data_partner_ids.push(_linkedin_partner_id);
              `}
          </script>
        )}

        {!!linkedInPartnerId && (
          <script type="text/javascript">
            {`
                (function(l) {
                  if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
                  window.lintrk.q=[]}
                  var s = document.getElementsByTagName("script")[0];
                  var b = document.createElement("script");
                  b.type = "text/javascript";
                  b.async = true;
                  b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                  s.parentNode.insertBefore(b, s);
                })(window.lintrk);
              `}
          </script>
        )}

        {!!linkedInPartnerId && (
          <noscript
            dangerouslySetInnerHTML={{
              __html: `
              <img
                height="1"
                width="1"
                style="display: none;"
                alt=""
                src="https://px.ads.linkedin.com/collect/?pid=${linkedInPartnerId}&fmt=gif"
              />
            `,
            }}
          />
        )}

        {/* ---- Google Ads ---- */}
        {/* <script
          async
          src={"https://www.googletagmanager.com/gtag/js?id=AW-CODE"}
        ></script>

        <script>
          {`
            window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-CODE');
          `}
        </script> */}

        {/* ---- Twitter ---- */}
        {/* <script type="text/plain">
          {`
            !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
            },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
              a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
              twq('config','TWITTER-CODE');
          `}
        </script> */}
      </Helmet>
    </>
  );
};

export default Analytics;
