// extracted by mini-css-extract-plugin
export var disclaimer = "styles-module--disclaimer--5b80a";
export var gridContainer = "styles-module--grid-container--8136c";
export var gridContent = "styles-module--grid-content--36f7b";
export var paddingGlobal = "styles-module--padding-global--fdcc9";
export var paddingHeader = "styles-module--padding-header--77c91";
export var paragraphFontSize = "styles-module--paragraph-font-size--1c0d1";
export var primaryButton = "styles-module--primary-button--136e3";
export var textBase = "styles-module--text-base--c8de8";
export var textDescription = "styles-module--text-description--f7eda";
export var textHeadline = "styles-module--text-headline--2e868";
export var titleFontSize = "styles-module--title-font-size--33764";