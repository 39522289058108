import { useForm } from "react-hook-form";
import { ISanityFooter } from "../../../types/commons";
import * as styles from "./styles.module.scss";
import { useState } from "react";

interface INewsletterFormProps {
  data: ISanityFooter["newsletterForm"];
  onValidated: any;
  status: string | null;
}

interface FormInputs {
  FNAME: string;
  LNAME: string;
  COMPANY?: string;
  EMAIL: string;
}

export default function NewsletterForm({
  data,
  onValidated,
  status,
}: INewsletterFormProps) {
  const { isDesactived, title, cta } = data;

  if (isDesactived) {
    return null;
  }

  const [errorMessage, setErrorMessage] = useState(
    "One or more fields have an error. Please check and try again.",
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>();

  const onSubmit = async (formData: FormInputs) => {
    await onValidated({
      FNAME: formData.FNAME,
      LNAME: formData.LNAME,
      EMAIL: formData.EMAIL,
    });
  };

  const handleInputKeyEvent = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  return (
    <div className={styles.newsLetterForm}>
      <h2 className={styles.title}>{title || "Join our newsletter"}</h2>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className={styles.row}>
          <input
            className={errors.FNAME ? styles.inputError : "outlineGlobalV2"}
            placeholder="FIRST NAME"
            aria-label="First Name"
            aria-invalid={errors.FNAME ? "true" : "false"}
            aria-describedby={errors.FNAME ? "fname-error" : undefined}
            {...register("FNAME", { required: true })}
          />

          <input
            className={errors.LNAME ? styles.inputError : "outlineGlobalV2"}
            placeholder="LAST NAME"
            aria-label="Last Name"
            aria-invalid={errors.LNAME ? "true" : "false"}
            aria-describedby={errors.LNAME ? "lname-error" : undefined}
            {...register("LNAME", { required: true })}
          />
        </div>

        <div className={styles.row}>
          <input
            className={errors.COMPANY ? styles.inputError : "outlineGlobalV2"}
            placeholder="COMPANY"
            aria-label="Company"
            aria-invalid={errors.COMPANY ? "true" : "false"}
            aria-describedby={errors.COMPANY ? "company-error" : undefined}
            {...register("COMPANY", { required: true })}
          />

          <input
            className={errors.EMAIL ? styles.inputError : "outlineGlobalV2"}
            placeholder="EMAIL"
            type="email"
            aria-label="Email Address"
            aria-invalid={errors.EMAIL ? "true" : "false"}
            aria-describedby={errors.EMAIL ? "email-error" : undefined}
            onKeyUp={(event) => handleInputKeyEvent(event)}
            {...register("EMAIL", {
              required: true,
              pattern: {
                value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                message: "Enter a valid email address",
              },
            })}
          />
        </div>

        <div className={styles.btnAndMsg}>
          <button
            className={`${styles.btnSubmit} outlineGlobalV2`}
            type="submit"
            aria-label="Subscribe to Newsletter"
          >
            {status === "sending" && <div className={styles.spinner}></div>}{" "}
            {cta || "Subscribe"}
          </button>
          <p
            id="form-error-message"
            className={styles.errorMessage}
            aria-live="assertive"
          >
            {(errors.FNAME || errors.LNAME || errors.EMAIL || errors.COMPANY) &&
              errorMessage}
          </p>
        </div>
      </form>
    </div>
  );
}
