import { PortableText, PortableTextReactComponents } from "@portabletext/react";
import classNames from "classnames";
import { FunctionComponent } from "react";
import { IInternalLink } from "../../types/commons";
import InternalLink from "../InternalLink";

type BlockStyle =
  | "normal"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "blockquote";

type ListType = "bullet" | "number";

type MarkDecorator = "strong" | "em" | "code" | "underline" | "strike-through";

interface ExternalLink {
  _type: "externalLink";
  href: string;
}

interface BlockMark {
  _type: "mark";
  children: any[];
  mark: MarkDecorator | IInternalLink | ExternalLink;
}

export interface ISimpleText {
  _type: "block";
  style?: BlockStyle;
  listItem?: ListType;
  children: (string | BlockMark)[];
}

interface RichTextProps {
  value: ISimpleText[];
  customClassName?: string;
  color?: string;
  id?: string;
}

const components: Partial<PortableTextReactComponents> = {
  types: {},
  marks: {
    strong: ({ children }) => <strong>{children}</strong>,
    em: ({ children }) => <em>{children}</em>,
    code: ({ children }) => <code>{children}</code>,
    underline: ({ children }) => <u>{children}</u>,
    "strike-through": ({ children }) => <s>{children}</s>,
    internalLink: ({ children, value }) => {
      return (
        <InternalLink reference={value.reference}>{children}</InternalLink>
      );
    },
    externalLink: ({ value, children }) => {
      const isURL = value.href.startsWith("http");
      const rel = isURL ? "noreferrer noopener" : "";
      const target = isURL ? "_blank" : "_self";
      return (
        <a className="outlineGlobalV2" href={value.href} target={target} rel={rel}>
          {children}
        </a>
      );
    },
  },
};

const RichText: FunctionComponent<RichTextProps> = ({
  value,
  customClassName,
  color,
  id
}) => {
  return (
    <div
      className={classNames("rich-text", customClassName)}
      style={{ color: color }}
      id={id}
    >
      <PortableText value={value} components={components} />
    </div>
  );
};

export default RichText;
