import MailchimpSubscribe from "react-mailchimp-subscribe";
import { ISanityFooter, ISanitySettings } from "../../types/commons";
import RichText from "../RichText";
import SanityImage from "../SanityImage";
import NewsletterForm from "./NewsletterForm";
import SocialLink from "./SocialLink";
import * as styles from "./styles.module.scss";
import { useState } from "react";

interface IFooterProps {
  data: {
    sanitySettings?: ISanitySettings | null;
    sanityFooter?: ISanityFooter | null;
  };
}

export default function Footer({ data }: IFooterProps) {
  if (!data) {
    return null;
  }
  const { sanitySettings, sanityFooter } = data;
  const [isFormSended, setIsFormSend] = useState(false);

  if (!sanityFooter || !sanitySettings) {
    return null;
  }

  const {
    certificate,
    _rawSocial,
    _rawTextSection,
    _rawIcons,
    newsletterForm,
  } = sanityFooter;

  return (
    <footer className={styles.footer} role="contentinfo" id="footer">
      <div className={styles.container}>
        <div className={styles.section}>
          {!!certificate && (
            <div className={styles.certificate}>
              <SanityImage src={certificate.asset.url} alt={certificate.alt} />
            </div>
          )}

          <div className={styles.column}>
            {!!_rawSocial && _rawSocial.length > 0 && (
              <div className={styles.social} aria-label="Social Media Links">
                {_rawSocial.map((item) => (
                  <SocialLink data={item} key={item._key} />
                ))}
              </div>
            )}

            {!!_rawTextSection && _rawTextSection.length > 0 && (
              <div className={styles.textSection}>
                {_rawTextSection.map((item) => (
                  <RichText
                    customClassName={styles.text}
                    value={item.text}
                    key={item._key}
                  />
                ))}
              </div>
            )}

            {!!_rawIcons && _rawIcons.length > 0 && (
              <div
                className={styles.icons}
                aria-label="Certificates and Awards"
              >
                {_rawIcons.map((item) => (
                  <SanityImage
                    key={item._key}
                    src={item.asset.url}
                    alt={item.alt}
                  />
                ))}
              </div>
            )}
          </div>
        </div>

        {!newsletterForm?.isDesactived &&
          (isFormSended ? (
            <div className={styles.formSended} aria-live="polite">
              <p>Thank you for signing up!</p>
            </div>
          ) : (
            <MailchimpSubscribe
              url="https://publicinc.us14.list-manage.com/subscribe/post?u=6d6d600c3c0e9a7edfeebef69&amp;id=ba2afc4e3e&amp;f_id=0067b9e5f0"
              render={(props) => {
                const { subscribe, status } = props || {};

                if (status === "success") {
                  setIsFormSend(true);
                }
                return (
                  <NewsletterForm
                    data={newsletterForm}
                    onValidated={(formData: any) => subscribe(formData)}
                    status={status}
                  />
                );
              }}
            />
          ))}
      </div>
    </footer>
  );
}
