import { Link } from 'gatsby';
import { ComponentPropsWithoutRef } from 'react';
import { IInternalLink } from '../types/commons';
import { formatInternalLink } from '../utils/linksUtils';


interface IInternalLinkProps extends Omit<ComponentPropsWithoutRef<typeof Link>, 'to'> {
  reference: IInternalLink['reference']
}

export default function InternalLink({ reference, children, ...props }: IInternalLinkProps) {
  const url = formatInternalLink(reference);

  return (
    <Link to={url} {...props}>
      {children}
    </Link>
  )
}
