export const IconInstagram = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 30 30"
  >
    <g fill="currentColor" clipPath="url(#a)">
      <path d="M7.039.487c-1.98.32-3.336 1.001-4.67 2.329C1.414 3.772.824 4.704.412 5.912.006 7.102.03 6.547.03 15.097s-.023 7.994.383 9.185c.412 1.207 1.002 2.14 1.957 3.096.956.955 1.889 1.545 3.096 1.957 1.19.406.636.383 9.185.383 8.55 0 7.995.023 9.185-.383 1.208-.412 2.14-1.002 3.096-1.957.956-.956 1.545-1.889 1.957-3.096.406-1.19.384-.636.384-9.185 0-8.55.023-7.995-.384-9.185-.412-1.208-1.001-2.14-1.957-3.096-.956-.956-1.888-1.545-3.096-1.957-1.196-.406-.538-.378-9.013-.39-4.2-.005-7.703 0-7.783.018Zm15.21 2.707c1.117.223 1.895.635 2.72 1.425.858.818 1.35 1.71 1.584 2.878.11.532.115.807.115 7.6 0 6.793-.006 7.067-.115 7.6-.234 1.167-.726 2.06-1.585 2.878-.824.79-1.602 1.202-2.718 1.425-.532.109-.807.114-7.6.114-6.793 0-7.067-.005-7.6-.114-1.115-.223-1.894-.635-2.718-1.425-.858-.818-1.35-1.711-1.585-2.878-.109-.533-.114-.807-.114-7.6 0-6.793.005-7.068.114-7.6.235-1.167.727-2.06 1.585-2.878.819-.784 1.597-1.196 2.696-1.425.469-.098.944-.11 7.582-.11 6.839-.005 7.108 0 7.64.11Z" />
      <path d="M22.027 5.385a1.984 1.984 0 0 0-1.316 1.179c-.166.44-.137 1.11.069 1.516.715 1.454 2.792 1.442 3.473-.011.132-.28.155-.4.155-.841 0-.578-.097-.853-.458-1.276-.418-.487-1.293-.744-1.923-.567ZM13.34 7.634c-.641.11-1.585.43-2.163.739-1.54.818-2.593 1.9-3.337 3.433-.572 1.185-.715 1.843-.715 3.29 0 1.449.143 2.107.715 3.291.779 1.603 1.918 2.741 3.52 3.52 1.184.572 1.843.715 3.29.715 1.448 0 2.106-.143 3.291-.715.979-.475 1.723-1.036 2.386-1.803.51-.584.784-1.001 1.133-1.717.573-1.184.716-1.842.716-3.29 0-1.11-.069-1.608-.326-2.375-.155-.464-.619-1.414-.888-1.814-1.161-1.734-3.032-2.947-5.053-3.28-.67-.102-1.911-.102-2.57.006Zm2.558 2.713c1.745.492 3.01 1.763 3.514 3.53.183.642.183 1.798 0 2.439-.51 1.78-1.763 3.033-3.543 3.542-.64.183-1.797.183-2.438 0-1.78-.51-3.032-1.763-3.542-3.542-.183-.641-.183-1.797 0-2.438.555-1.94 2.032-3.285 4.006-3.646.446-.085 1.534-.022 2.003.115Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 .447h29.3v29.3H0z" />
      </clipPath>
    </defs>
  </svg>
);
