// extracted by mini-css-extract-plugin
export var active = "styles-module--active--bfa06";
export var container = "styles-module--container--bf3af";
export var gridContainer = "styles-module--grid-container--2bf7f";
export var gridContent = "styles-module--grid-content--5326b";
export var hamburger = "styles-module--hamburger--8b2d7";
export var header = "styles-module--header--6be34";
export var light = "styles-module--light--65d29";
export var link = "styles-module--link--3d825";
export var links = "styles-module--links--50a58";
export var logo = "styles-module--logo--65bd9";
export var navigation = "styles-module--navigation--90fce";
export var open = "styles-module--open--fd374";
export var paddingGlobal = "styles-module--padding-global--cd3da";
export var paddingHeader = "styles-module--padding-header--125f9";
export var paragraphFontSize = "styles-module--paragraph-font-size--199cb";
export var primaryButton = "styles-module--primary-button--76ea0";
export var scrolled = "styles-module--scrolled--811c4";
export var skipLink = "styles-module--skipLink--243d7";
export var textBase = "styles-module--text-base--bc0e9";
export var textDescription = "styles-module--text-description--ddb19";
export var textHeadline = "styles-module--text-headline--0516e";
export var titleFontSize = "styles-module--title-font-size--8ad6b";