import { FitMode, ImageFormat, SanityImageSource } from '@sanity/image-url/lib/types/types';
import classNames from 'classnames';
import { KeyboardEventHandler, MouseEventHandler, useEffect, useState } from 'react';
import { useImageBuilder } from '../../hooks/useImageBuilder';
import * as styles from "./styles.module.scss";

interface ISanityImage {
  src: SanityImageSource
  alt: string
  className?: string
  width?: number
  height?: number
  fit?: FitMode
  quality?: number
  format?: ImageFormat
  click?: MouseEventHandler<HTMLImageElement>
  tabIndex?: number
  onKeyDown?: KeyboardEventHandler<HTMLImageElement>
  role?: string;
  style?: any;
}

export default function SanityImage({
  src,
  alt,
  className,
  width,
  height,
  fit,
  quality,
  format,
  click,
  tabIndex,
  onKeyDown,
  role,
  style
}: ISanityImage) {
  const imageBuilder = useImageBuilder();
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    let base = imageBuilder.image(src);

    if (width) {
      base = base.width(width);
    }

    if (height) {
      base = base.height(height);
    }

    if (fit) {
      base = base.fit(fit);
    }

    if (quality) {
      base = base.quality(quality);
    }

    if (format) {
      base = base.format(format);
    }

    if (!format) {
      base = base.auto('format');
    }

    setImageUrl(base.url());
  }, [src, width, height, fit, quality, format]);

  return (
    <img
      className={classNames(styles.sanityImage, 'sanity-image', className)}
      src={imageUrl}
      alt={alt}
      onClick={click}
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      role={role}
      style={style}
    />
  )
}
